@import "fonts", "hamburger";
@import "multi-carousel/multi-carousel";

.uk-light .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav, .uk-card-secondary.uk-card-body .uk-slidenav, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav, .uk-overlay-primary .uk-slidenav, .uk-offcanvas-bar .uk-slidenav {
  color: white;

  svg {
    width: 40px;
  }
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 120px!important;
  width: 90px!important;
}

html {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 100%; /*16px*/
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

.bold {
  font-weight: bold;
}

.blue {
  color: #252c41;
}
.pink {
  color: #9d3638;
}

.border-strong {
  border: 1px solid rgba(157, 54, 56, 0.3);
}

.block {
  display: block;
}

.center {
  text-align: center;
}

.none {
  display: none;
}

a {
  text-decoration: none;
}

a.strong {
  color: #9d3638;
  font-weight: bold;
}

.custom-lnk {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  list-style: none;
  margin: 1.5rem auto;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  letter-spacing: 1px;
  vertical-align: baseline;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-style: italic;
  color: #252c41;

  a {
    color: #252c41;
  }
}

.tel-ft {
  color:#9d3638;
  font-weight: bold;;
}

.partenaire {
  color:#ffffff;
  font-size: 9px;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

.main-contact-form {
  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  button:disabled:hover,
  button[disabled]:hover {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  button {
    background-color: #9d3638;
    padding: 1rem;
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
    vertical-align: baseline;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #9a5354;
  }
}

h2 {
  color: #252c41;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

#contact,
#cocktails-dinatoire,
#gala,
#seminaire,
#buffets,
#traiteur-service,
#anniversaire,
#bapteme,
#traiteur-cocktails {
  scroll-margin-top: 100px;
}

.color {
  width: 100%;
  text-align: center;
  .bg {
    display: inline-block;
    background-color: #0a2133;
    height: 2px;
    width: 5rem;
  }
}

.dropdown-child {
  display: none;
}

.dropdown {
  position: relative;
}

.dropdown:hover {
  .dropdown-child {
    display: block;
    padding-top: 1rem;
    position: absolute;
    left: -25px;
    background-color: rgba($color: #ffffff, $alpha: 1);
    border: 1px solid rgba(0, 0, 0, 1);
    border-top: none;
    a {
      padding: 0rem 1em 0.5rem;
    }
  }
  a {
    text-decoration: none;
    display: block;
  }
}

.contact-form {
  input,
  textarea {
    width: 100%;
    background-color: #ffffff;
    color: #444444;
    border-color: #e1e1e1;
    padding: 13px 10px 13px 10px;
    font-size: 100%;
    border: 1px solid #e1e1e1;
    outline: none;
  }
  .submit {
    text-align: center;
  }
  .errors {
    border: 2px solid #f74b00;
    margin: 2em 0em 1em;
    padding: 20px;
    color: #333;
  }
  .success {
    border: 2px solid #8df700;
    margin: 2em 0em 1em;
    padding: 20px;
    color: #333;
  }
}
.main-header {
  z-index: 1;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "logo  menu  cell";
  height: 6rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1.5px solid #9d363826;

  .logo,
  .menu,
  .cell {
    align-items: center; // vertical align
    justify-items: center; // horizontal align
    align-self: center;
    text-align: center;
  }

  .logo {
    font-size: 1.5rem;
    padding: 0 0 0 .7rem;
    color: rgb(231, 231, 237);

    .abs {
      font-size: 0.8rem;
    }
  }

  .menu {
    grid-area: menu;
    display: grid;
    font-size: 1rem;
    grid-template-columns: 1fr repeat(7, auto) 1fr;
    grid-column-gap: 1.5rem;

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: #9d3638;
    }

    div {
      text-transform: uppercase;
      a {
        text-decoration: none;
        color: #000;
        letter-spacing: 1px;
        text-transform: uppercase;
        vertical-align: baseline;
        &.selected {
          color: #9d3638;
          padding-bottom: 0rem;
          border-bottom: 2px solid #9d3638;
          font-weight: bold;
        }
      }
      a:hover {
        color: #9d3638;
        // padding-bottom: 0.5rem;
        // border-bottom: 2px solid #9d3638;
      }
    }
  }
  .cell {
    grid-area: cell;
    // a {
    //   text-decoration: none;
    //   color: #9d3638;
    //   letter-spacing: 1px;
    //   text-transform: uppercase;
    //   font-weight: bold;
    // }
    // a:hover {
    //   text-decoration: underline;
    // }
  }
}

.responsive-menu {
  background: #252c41;
  text-transform: uppercase;
  div {
    padding-top: 0.4rem;
    min-height: 2rem;
    border-bottom: 1px solid #ffffff;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: rgb(231, 231, 237);
    letter-spacing: 1px;
    text-transform: uppercase;
    vertical-align: baseline;
    &.selected {
      color: #9d3638;
      padding-bottom: 0.5rem;
    }
  }
  a:hover {
    color: #9d3638;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #9d3638;
  }
}

.traiteur-entreprise-content {
  // background: url("../assets/gdc/lieu-entreprise.png");
  // background-size: cover;
  // height: auto;
  // width: 100%;
  // background-position: 50% 50%;
  // height:100%;
  // width:100%;
  overflow: hidden;
}
.traiteur-video video  {
  height: 100vh;
  width: 100%;
  object-fit: cover; // use "cover" to avoid distortion
  position: absolute;
  // min-width: 100%;
  // min-height: 100%;
  // object-fit: fill;
}

.traiteur-content {
  background: url("../assets/gdc/magret.jpg");
  background-size: cover;
  min-height: 450px;
  background-position: 50% 80%;
}

.livraison-content {
  background: url("../assets/covers/livraison.jpg");
  background-size: cover;
  min-height: 550px;
  background-position: 50% 45%;
}

.main-content {
  min-height: 550px;
  background-position: 0% 58%;
  background-image: url("../assets/gdc/gdc-accueil-home.jpg");
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 8rem;

  .cta-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .ctas {
      display: grid;
      grid-template-columns: repeat(3, 22rem);
      text-align: center;

      .cta-offer {
        background-color: rgba(250, 250, 250, 0.9);
        padding: 10px;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 0;
        height: fit-content;
        text-decoration: none;

        a {
          text-decoration: none;
        }

        .subtitle-offer {
          color: #9d3638;
          font-size: 30px;
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: -4px;
          line-height: 1em;
        }
        .title-offer {
          color: #284150;
          font-size: 19px;
          letter-spacing: -1px;
        }
      }
    }
  }

  .desc {
    background-color: rgba(255, 255, 255, 0.85);
    padding: 2rem;
    height: 80%;
    margin-right: 2rem;

    .subtitle {
      font-weight: 400;
      font-family: Roboto, sans-serif;
      color: #70778b;
      letter-spacing: 0.02em;
      margin-bottom: 2rem;
    }
  }
}

.teaser {
  display: grid;
  grid-template-columns: 1fr 80% 1fr;
  padding: 2rem 0;
  background-color: #f5f5f5;
  .title {
    align-items: center; // vertical align
    justify-items: center; // horizontal align
    align-self: center;
    text-align: center;
    font-size: 1.5rem;
  }
  .text {
    text-align: justify;
    font-size: 1rem;
  }
}

.mariagenet {
  display: grid;
  grid-template-columns: 1fr 30% 30% 1fr;
  padding: 2rem 0;
  background-color: #fafafa;

  .italic {
    font-style: italic;
    color: #252c41;
  }
  .title {
    align-items: center; // vertical align
    justify-items: center; // horizontal align
    align-self: center;
    text-align: center;
    font-size: 3rem;
  }
  .desc {
    text-align: center;
  }
}

.spinoff {
  display: grid;
  grid-template-columns: 1fr 30% 50% 1fr;
  padding: 2rem 0;
  background-color: #fafafa;

  .italic {
    font-style: italic;
    color: #252c41;
  }
  .title {
    align-items: center; // vertical align
    justify-items: center; // horizontal align
    align-self: center;
    text-align: center;
    font-size: 3rem;
  }
}

.spinoff-large {
  grid-template-columns: 1fr 30% 50% 1fr;
  padding: 2rem 0;
  background-color: #fafafa;

  .italic {
    font-style: italic;
    color: #252c41;
  }
  .title {
    align-items: center; // vertical align
    justify-items: center; // horizontal align
    align-self: center;
    text-align: center;
    font-size: 3rem;
  }
  .desc {
    margin: 0 5rem;
  }
}

.cta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;
  background-color: #fafafa;
  .title {
    text-align: right;
    font-size: 2rem;
    padding-left: 3rem;
  }

  .desc {
    text-align: left;
    padding: 0.7rem 3rem;
    .button {
      background-color: #9d3638;
      border-color: #9d3638;
      padding: 1rem;

      text-decoration: none;
      color: #ffffff;
      letter-spacing: 1px;
      text-transform: uppercase;
      vertical-align: baseline;
    }
    .button:hover {
      text-decoration: underline;
    }
  }
}

.full-cover {
  h2 {
    text-align: center;
    text-emphasis: center;
  }

  &.cocktails {
    background: url("../assets/gdc/buffet-ets.jpg");
    background-size: cover;
    width: 100%;
    background-position: 0% 62%;
  }

  &.traiteur-cocktails {
    background: url("../assets/gdc/buffet-ets.jpg");
    background-size: cover;
    width: 100%;
    background-position: 0% 62%;
  }

  &.gala {
    background: url("../assets/gdc/gala.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 50%;
  }
  &.seminaire {
    background: url("../assets/gdc/seminaire.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 50%;
  }
  &.buffets {
    background: url("../assets/gdc/ecrevisse.jpg");
    background-size: cover;
    width: 100%;
    background-position: 0% 0%;
  }

  &.traiteur {
    background-image: url("../assets/gdc/mariage.jpg");
    background-size: cover;
    width: 100%;
    background-position: 100% 70%;
  }

  &.foodtrucks {
    background: url("../assets/gdc/club-saumon.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 60%;
  }

  &.livraison {
    background: url("../assets/gdc/salle.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 50%;
  }

  &.nos-foodtrucks {
    background: url("../assets/foodtruck/nos-foodtrucks.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 50%;
  }

  &.traiteur-service {
    background: url("../assets/gdc/interieur.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 50%;
  }

  &.anniversaire {
    background: url("../assets/gdc/anniversaire.jpg");
    background-size: cover;
    width: 100%;
    background-position: 0% 45%;
  }

  &.bapteme {
    background: url("../assets/gdc/dampierre.jpg");
    background-size: cover;
    width: 100%;
    background-position: 0% 70%;
  }

  &.service-livraison {
    background: url("../assets/livraison/service-livraison.jpg");
    background-size: cover;
    width: 100%;
    background-position: 50% 50%;
  }

  .grid {
    &.left,
    &.right {
      display: grid;
      grid-template-columns: 50% 1fr;
    }
  }

  .desc {
    background-color: #ffffff;
    margin: 3rem;
    .txt {
      margin: 2rem;
    }
  }
}

.cover {
  h2 {
    text-align: center;
    text-emphasis: center;
  }

  .summary {
    padding: 0 2rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .img.burger {
      background: url("../assets/burger.jpg");
      background-size: cover;
      width: 100%;
      height: 545px;
      background-position: 50% 50%;
    }
    .img.traiteur {
      background: url("../assets/traiteur.jpg");
      background-size: cover;
      width: 100%;
      height: 545px;
      background-position: 50% 50%;
    }
    .img.trucks {
      background: url("../assets/foodtrucks.jpg");
      background-size: cover;
      width: 100%;
      height: 545px;
      background-position: 50% 50%;
    }
    .img.livraison {
      background: url("../assets/livraison.jpg");
      background-size: cover;
      width: 100%;
      height: 545px;
      background-position: 50% 50%;
    }
    .img.xavier-pincemin {
      background: url("../assets/xavier-pincemin.jpg");
      background-size: cover;
      width: 100%;
      height: 600px;
      background-position: 50% 50%;
    }
    .img.nos-foodtrucks {
      background: url("../assets/foodtruck/nos-foodtrucks.jpg");
      background-size: cover;
      width: 100%;
      height: 800px;
      background-position: 50% 50%;
    }
    .img.nos-foodtrucks-big {
      background: url("../assets/foodtruck/nos-foodtrucks.jpg");
      background-size: cover;
      width: 100%;
      height: 1370px;
      background-position: 50% 50%;
    }
    .img.oldschool {
      background: url("../assets/gdc/ardoise.jpg");
      background-size: cover;
      width: 100%;
      height: 800px;
      background-position: 50% 50%;
    }
    .img.notre-service-traiteur {
      background: url("../assets/traiteur/notre-service.jpg");
      background-size: cover;
      width: 100%;
      height: 800px;
      background-position: 40% 50%;
    }

    .img.livraison {
      background: url("../assets/livraison/service-livraison.jpg");
      background-size: cover;
      width: 100%;
      height: 640px;
      background-position: 100% 50%;
    }
    .img.menu-traiteur {
      background: url("../assets/gdc/ardoises.jpg");
      background-size: cover;
      width: 100%;
      height: 1080px;
      background-position: 50% 50%;
    }
    .img.menu-traiteur2 {
      background: url("../assets/gdc/ardoise.jpg");
      background-size: cover;
      width: 100%;
      height: 830px;
      background-position: 50% 50%;
    }
    .img.menu-traiteur3 {
      background: url("../assets/gdc/ecrevisse.jpg");
      background-size: cover;
      width: 100%;
      height: 830px;
      background-position: 50% 50%;
    }
    .img.menu-traiteur4 {
      background: url("../assets/gdc/canapes.jpg");
      background-size: cover;
      width: 100%;
      height: 830px;
      background-position: 50% 50%;
    }
    .img.menu-traiteur5 {
      background: url("../assets/gdc/dinatoire.jpg");
      background-size: cover;
      width: 100%;
      height: 830px;
      background-position: 50% 50%;
    }
    .img.menu-traiteur6 {
      background: url("../assets/gdc/tomates-cerise.jpg");
      background-size: cover;
      width: 100%;
      height: 830px;
      background-position: 50% 50%;
    }
    .img.menu-dessert {
      background: url("../assets/gdc/buffets.jpg");
      background-size: cover;
      width: 100%;
      height: 632px;
      background-position: 50% 50%;
    }
  }

  .desc {
    .txt {
      margin: 2rem;
    }
  }

  .link-in {
    a {
      font-weight: bold;
      color: #252c41;
    }
  }
}

.clients-testimonials {
  h2 {
    text-align: center;
    text-emphasis: center;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "testimonial client";
  }

  .clients {
    grid-area: client;
    // height: 5rem;
    padding: 3rem 5rem;
    // min-height: 40rem;
    .row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      // height: 116px;
      margin-bottom: 2rem;
      text-align: center;
    }

    .box {
      height: 116px;
      img {
        width: 155px;
        height: 116px;
      }
    }
  }

  .testimonial {
    grid-area: testimonial;
    padding: 2rem 5rem;

    .block {
      padding: 6rem 0rem;
      min-height: 24rem;
      background: rgba(0, 0, 0, 0.05);
    }
    .stars {
      display: grid;
      grid-template-columns: 1fr repeat(5, 19px) 1fr;
      grid-column-gap: 1.5rem;
      align-items: center; // vertical align
      justify-items: center; // horizontal align
      align-self: center;
      text-align: center;
      padding-bottom: 1rem;
    }
    img {
      width: 19px;
      height: 20px;
    }
    .description {
      font-size: 1rem;
      text-align: left;
      width: 90%;
      margin: 0 auto;
      line-height: 1.9rem;
    }
    .author {
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      padding-bottom: 1rem;
    }
  }
}

.main-footer {
  border-color: #444444;
  background: #1b1b1b;
  color: #cacaca;
  font-weight: bold;
  text-align: left;
  padding: 1rem 5rem;
  font-size: 0.6rem;
}

.second-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-color: #444444;
  background-color: #262626;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  padding: 1rem 5rem;
  font-size: 0.8rem;
  line-height: 1.5rem;

  .snapwidget {
    max-width: 375px;
  }

  .desc {
    padding-right: 10rem;

    .second {
      margin-top: 1rem;
    }
  }

  .social {
    .icon--light-black {
      fill: #494d50;
    }
    .icon--large {
      height: 1.5rem;
      width: 1.5rem;
    }
    svg.fb {
      fill: rgb(24, 119, 242);
      width: 2rem;
    }
    svg.insta {
      fill: #e4405f;
      width: 2rem;
    }
    .icon {
      fill: #757575;
      height: 1.2rem;
      transition: fill 0.2s ease-in;
      width: 1.2rem;
    }
    svg {
      overflow: hidden;
    }
    img,
    svg {
      vertical-align: middle;
    }
    a {
      color: #8764b4;
      text-decoration: none;
      background-color: transparent;
    }
    .main-footer__item--social {
      max-width: 10rem;
    }
    .main-footer__item {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-around;
      justify-content: space-around;
      list-style: none;
      margin: 1.5rem auto;
      padding-left: 0;
      text-align: center;
    }
  }
  .zoom-instagram-widget__follow-me {
    margin-top: 15px;
    text-align: center;

    a.ig-b-v-24 {
      border-color: #3897f0;
      color: #3897f0 !important;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;
      font-weight: 600 !important;
      outline: none;
      overflow: hidden;
      white-space: nowrap;
      -webkit-appearance: none;
      font-family: sans-serif;
      padding: 5px 15px;
      font-size: 14px;
      transition: all 0.15s ease-in-out;
      -moz-transition: all 0.15s ease-in-out;
      -webkit-transition: all 0.15s ease-in-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none !important;
    }

    .ig-b- {
      display: inline-block;
    }
  }

  /* Some very basic styles to make things look sensible - you don't need to use these */

  * {
    box-sizing: border-box;
    font-family: sans-serif;
  }

  #instafeed {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    max-width: 64rem;
    margin: auto;
  }

  #instafeed a {
    display: block;
  }

  #instafeed img {
    display: block;
    width: 100%;
  }
}

.ctas-mobile {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;

  .cta-offer {
    background-color: rgba(250, 250, 250, 0.9);
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 0;
    height: fit-content;
    text-decoration: none;

    a {
      text-decoration: none;
    }

    .subtitle-offer {
      color: #9d3638;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: -4px;
      line-height: 1em;
    }
    .title-offer {
      color: #284150;
      font-size: 19px;
      letter-spacing: -1px;
    }
  }
}

.propos-contact {
  text-align: center;
  background-color: rgba($color: #9d3638, $alpha: 0.1);
  border: 1px solid rgba($color: #9d3638, $alpha: 0.3);
  div {
    padding: 20px;
    font-weight: bold;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 100px) {
  .mariagenet {
    grid-template-columns: 1fr;
  }

  .main-content,
  .traiteur-entreprise-content,
  .traiteur-content,
  .livraison-content {
    min-height: 550px;
  }

  .second-footer {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 3rem;

    .desc {
      padding-right: 1rem;
    }
  }

  .main-header {
    grid-template-columns: 1fr 1fr;

    .menu {
      grid-template-columns: 1fr 1fr;
    }
    .number {
      display: none;
    }
    .responsive-link {
      display: block;
    }

    .logo {
      height: 69px;
    }
  }
  .cta {
    grid-template-columns: 1fr;
    grid-template-areas: "1fr" "1fr";
    .title {
      padding-left: 0rem;
      text-align: center;
    }
    .desc {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .full-cover {
    .grid {
      &.left,
      &.right {
        display: grid;
        grid-template-columns: 1fr;
      }
      .desc {
        margin: 1.5rem;
      }
    }
  }

  .cover {
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: "1fr" "1fr";
    }
  }

  .spinoff {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "1fr" "1fr" "1fr" "1fr";
    padding: 1rem;
  }

  .basic-description {
    padding: 1rem;
  }

  .clients-testimonials {
    .testimonial {
      grid-area: testimonial;
      padding: 1rem 1rem;

      .block {
        padding: 1rem 1rem;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
        "testimonial"
        "client";
    }

    .clients {
      padding: 1rem;

      .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        // height: 20rem;
        margin-bottom: 2rem;
        text-align: center;
      }
    }
  }

  .ctas-mobile {
    display: block;
  }

  .main-content {
    .cta-list {
      display: none;
    }
  }

  .cover {
    .grid {
      display: grid;
      grid-template-columns: "1fr" "1fr";
    }
  }

  .main-header {  
    .menu {
      grid-area: menu;
      display: grid;
      font-size: 1rem;
      grid-template-columns: 1fr repeat(2, auto) 1fr;
      grid-column-gap: 1.5rem;
      .link {
        display: none;
      }
    }
    .cell {
      text-align: right;
    }
    .responsive-link {
      display: block;
      text-align: right;
    }
    .number {
      display: none;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .full-cover {
    .grid {
      &.left {
        display: grid;
        grid-template-columns: 70% 1fr;
      }
      &.right {
        display: grid;
        grid-template-columns: 1fr 70%;
      }
      .desc {
        margin: 3rem;
      }
    }
  }

  .second-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 4rem;

    .desc {
      padding-right: 8rem;
    }
  }

  .main-header {
    grid-template-columns: 1fr 1fr;

    .menu {
      grid-template-columns: 1fr repeat(7, auto) 1fr;
    }
    .cell {
      display: block;
    }

    .logo {
      height: 70px;
    }
  }

  .cta {
    grid-template-columns: 1fr 1fr;

    .title {
      text-align: right;
      padding-left: 3rem;
    }
    .desc {
      margin-top: inherit;
      text-align: left;
    }
  }
  .spinoff {
    display: grid;
    grid-template-columns: 1fr 30% 50% 1fr;
  }
  .cover {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .clients-testimonials {
    .testimonial {
      padding: 2rem 5rem;

      .block {
        padding: 6rem 0rem;
      }
    }
    .clients {
      padding: 3rem 5rem;

      .row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        // height: 116px;
        margin-bottom: 2rem;
        text-align: center;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .mariagenet {
    grid-template-columns: 1fr 30% 30% 1fr;
  }
  .main-header {
    grid-template-columns: 30% 1fr;

    .logo {
      height: 80px;
    }
  }
}

@media (min-width: 1100px) {
  .full-cover {
    .grid {
      &.left,
      &.right {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .main-content,
  .traiteur-content {
    min-height: 600px;
  }
  .traiteur-entreprise-content {
    min-height: 600px;
  }
  .livraison-content {
    min-height: 600px;
  }

  .ctas-mobile {
    display: none;
  }

  .main-content {
    .cta-list {
      display: grid;
    }
  }

  .main-header {
    grid-template-columns: 25% 1fr;

    .cell {
      text-align: center;
      .number {
        display: none;
      }
      .responsive-link {
        display: none;
      }
    }

    .menu {
      grid-area: menu;
      display: grid;
      font-size: 0.8rem;
      grid-template-columns: 1fr repeat(7, 7rem) 1fr;
      grid-column-gap: 0rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;

      .link {
        display: block;
      }
    }
  }

  .main-content {
    display: grid;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .clients-testimonials {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "testimonial client";
    }
  }
}

@media (min-width: 1200px) {
  .main-content,
  .traiteur-entreprise-content,
  .traiteur-content,
  .livraison-content {
    min-height: 630px;
  }
}

@media (min-width: 1300px) {
  .main-header {
    grid-template-columns: 30% 1fr;

    .menu {
      grid-template-columns: 1fr repeat(7, 8rem) 1fr;
    }
  }
}

:root {
  --star-size: 60px;
  --star-color: #fff;
  --star-background: #0a2133;
}

.notes {
  text-align: center;
  margin: 2rem 0;
  font-size: 1rem;

  .important {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .big-stars {
    --percent: calc(var(--rating) / 5 * 100%);

    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;

    &::before {
      content: "★★★★★";
      letter-spacing: 3px;
      background: linear-gradient(
        90deg,
        var(--star-background) var(--percent),
        var(--star-color) var(--percent)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.wrap-collabsible {
  input[type="checkbox"] {
    display: none;
  }

  margin: 1.2rem 0;

  .lbl-toggle {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: left;
    padding: 0;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.25s ease-out;
  }
  .lbl-toggle:hover {
    color: #9d3638;
  }
  .lbl-toggle::before {
    content: " ";
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out;
  }
  .toggle:checked + .lbl-toggle {
    font-style: italic;
  }
  .toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
  }
  .collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
  }
  .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 200vh;
  }
  .toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .collapsible-content .content-inner {
    padding: 0;
  }
  .collapsible-content p {
    margin-bottom: 0;
  }
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";
.image-gallery-slide .image-gallery-image {
  object-fit: fill;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  left: 0;
  line-height: 1.5;
  top: 30px;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
  height: fit-content;
  width: fit-content;
  text-align: left;
  font-size: 1.3rem;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}

.avis {
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
  padding: 1rem;
  background-color: #fafafa;

  .show {
    display: block;
  }
  .none {
    display: none;
  }
}

.cover-flow {
  min-height: 300px;
  padding: 2rem 0;
}

// import 'react-multi-carousel/lib/styles.css';

.react-multi-carousel-list {
  padding: 1rem 0;
  // height: 290px;
  height: auto;
}

.jss1 {
  margin: 0 20px;
}
.jss3 {
  overflow: hidden;
}
.jss7 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.jss5 {
  border-radius: 4px;
}
.jss4 {
  background-color: #fff;
}

.jss2 {
  height: 250px;
}
.jss37 {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.jss31 {
  width: 100%;
  display: block !important;
  text-align: inherit;
}
.jss34 {
  color: inherit;
  border: 0;
  margin: 0;
  cursor: default;
  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.jss1 {
  margin: 0 20px;
}
.jss3 {
  overflow: hidden;
}
.jss7 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.jss5 {
  border-radius: 4px;
}
.jss4 {
  background-color: #fff;
}
.jss39 {
  padding: 16px;
}
.jss67 {
  margin-bottom: 0.35em;
}
.jss56 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0em;
}
.jss40 {
  margin: 0;
  display: block;
  text-align: justify;
}

.jss49 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
}
.jss40 {
  margin: 0;
  display: block;
}
.jss33 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  background-color: currentcolor;
}
.jss126 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}
.jss76 {
  // display: flex;
  padding: 8px 4px;
  box-sizing: border-box;
  align-items: center;
}
.jss78 {
  margin: 0 4px;
}

.jss102 {
  padding: 4px 8px;
  min-width: 64px;
  font-size: 0.8125rem;
}
.jss82 {
  color: #3f51b5;
}
.jss80 {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  cursor: pointer;

  a {
    color: black;
    font-weight: bold;
  }
}
.jss126 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}

.jss140 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}

.jss79 {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
}

.jss102 {
  padding: 4px 8px;
  min-width: 64px;
  font-size: 0.8125rem;
}
.jss82 {
  color: #3f51b5;
}